<template>
    <div class="home">
        <BlogHead class="blog-head" />
        <div class="blog-con">
            <BlogLeftSide class="blog-left-side" />
            <div class="left-side-seat"></div>
            <div class="blog-content">
                <router-view />
            </div>
            <BlogRightSide class="blog-right-side" />
        </div>
        <!-- 备案号 -->
        <div style="width: 300px; margin: 0 auto; padding: 20px 0">
            <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53038102530485"
                style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
                ><img src="" style="float: left" />
                <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #939393">滇公网安备 53038102530485号</p></a
            >
        </div>
        <div class="Copyright">
            <span>© {{ currentYear }} Copyright </span>
            <div>
                <span @click="goToICP('GongAn')"><img src="@/assets/images/gong-an-bei-an.png" alt="" /> 滇公网安备 53038102530485号 | </span>
                <span @click="goToICP('GongXin')">滇ICP备2025053477号-2 | Powered by puhaha</span>
            </div>
        </div>
        <!-- 返回顶部 -->
        <div class="back-top" v-show="showBackTop" @click="backTop">
            <span v-html="ARTICLE_ICON.backTop"></span>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    // @ is an alias to /src
    import BlogHead from './components/BlogHead.vue'
    import BlogLeftSide from './components/BlogLeftSide.vue'
    import BlogRightSide from './components/BlogRightSide.vue'
    import {ARTICLE_ICON} from '@/lib/article_const'
    export default {
        name: 'Home',
        components: {
            BlogHead,
            BlogLeftSide,
            BlogRightSide,
        },
        data() {
            return {
                allreadyLogin: false,
                ARTICLE_ICON,
                showBackTop: false,
            }
        },
        computed: {
            currentYear() {
                return new Date().getFullYear()
            },
        },
        methods: {
            ...mapActions({vxGetSession: 'user/getSession'}),
            changeLoginDialog() {
                this.allreadyLogin = !this.allreadyLogin
            },
            backTop() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            goToICP(str) {
                if (str === 'GongXin') {
                    window.open('https://beian.miit.gov.cn/#/Integrated/index')
                } else {
                    window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53038102530485')
                }
            },
        },
        async mounted() {
            const data = await this.vxGetSession()
            console.log(data)
            data.code && (this.allreadyLogin = true)
            window.addEventListener('scroll', (e) => {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                if (scrollTop > 260) {
                    this.showBackTop = true
                } else {
                    this.showBackTop = false
                }
            })
        },
    }
</script>

<style lang="less" scoped>
    .home {
        position: relative;
        max-width: @max-width;
        min-width: @min-width;
        min-height: 100vh;
        margin: 0 auto;
        box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.05);
    }
    .blog-head {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.05);
        z-index: 999;
    }
    .blog-con {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 100vh;
        padding-top: 50px;
        .blog-content {
            flex: 1;
        }
        .left-side-seat {
            width: 220px;
            height: 100vh;
            // background-color: pink;
        }
        .blog-left-side {
            position: fixed;
            width: 220px;
            // height: 100vh;
        }
        .blog-right-side {
            width: 240px;
        }
    }
    .Copyright {
        position: absolute;
        left: 220px;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        height: 50px;
        width: 100%;
        max-width: calc(@max-width - 220px);
        line-height: 50px;
        font-size: 14px;
        font-family: 'Source Sans Pro';
        color: #58666e;
        background-color: #edf1f2;
        cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
    }
    .back-top {
        position: fixed;
        top: 260px;
        right: 0;
        width: 43px;
        height: 43px;
        line-height: 43px;
        text-align: center;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        background-color: #f9f9f9;
        transition: all 0.3s;
        cursor: url('http://www.a-puhaha.com/static/image/link.cur'), pointer;
    }
</style>
